export const skills = [
    {
        titleEN: 'Skills in Computer Science',
        titleFR: 'Compétances en informatique',
        list: {
            en: [
                'Able to work in Windows, MacOs, and Linux environments.',
                'Full-stack web development working with both frontend and backend technologies.',
                'Native application development in IOS using Swift and SwiftUI.',
                'Native application development in Android using Kotlin or Java and Jetpack Compose or XML.',
                'Multiplatform application development using Kotlin Multiplatform and Compose Multiplatform.',
                'Create, use, and maintain databases in applications.',
                'Perform testing and debugging of code.',
                'Create and use APIs.'
            ],
            fr: [
                'Habileté de travailler dans un environnement Windows, MacOs et Linux.',
                'Développement web full-stack en utilisant des technologies frontend et backend.',
                'Développement d\'applications natives avec IOS en utilisant Swift et SwiftUI.',
                'Développement d\'applications natives avec Android en utilisant Kotlin ou Java et Jetpack Compose ou XML.',
                'Développement d\'applications multiplateformes en utilisant Kotlin Multiplateforme et Compose Multiplateforme.',
                'Créer, utiliser et maintenir des bases de données dans les applications.',
                'Effectuer des tests et le débogage des codes.',
                'Créer et utiliser des APIs.'
            ]
        }
    },
    {
        titleEN: 'Other Skills',
        titleFR: 'Autres compétances',
        list: {
            en: [
                'Create simple designs and logos using Adobe Photoshop and Adobe Illustrator.',
                'Make application mockups using Adobe XD.',
                'Experience in customer support with answering calls and customer inquiries.',
            ],
            fr: [
                'Créer des simples designs et logos en utilisant Adobe Photoshop et Adobe Illustrator.',
                'Utiliser Adobe XD pour créer des maquettes d\'applications.',
                'Expérience dans le support client en répondant aux appels et aux demandes des clients.',
            ]
        }
    }
]